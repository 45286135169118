/* eslint-disable */
import MainTitle from "../../components/MainTitle"

import { useState, useEffect, useRef } from "react"
import CardButton from "../../components/CardButton"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useAuth from "../../hooks/useAuth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Pagination from "../../components/Pagination"
import {
  faPencil,
  faTrashCan,
  faPlus,
  faMagnifyingGlass,
  faCheckCircle,
  faTimes
} from "@fortawesome/free-solid-svg-icons"
import Modal from "../../components/Modal"

function QuickLinks() {
  const axios = useAxiosPrivate()

  const { setShowLoader, auth } = useAuth()

  const [quicklinks, setQuickLinks] = useState([])
  const [editLink, setEditLink] = useState({})
  const [createLink, setCreateLink] = useState({})
  const [deleteLink, setDeleteLink] = useState({})
  const [showModal, setShowModal] = useState(false)
  const linkRef = useRef()
  const [showScreenDropdown, setShowScreenDropdown] = useState(false)

  const [currentRecords, setCurrentRecords] = useState([])
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  // User is currently on this page
  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [search, setSearch] = useState("")

  const [modalTitle, setModalTitle] = useState("")

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setShowLoader(true)
    await Promise.all([getQuickLinks()])
    setShowLoader(false)
  }

  const toggleSource = (source, value) => {
    if(source == 'external')
      {
        setShowScreenDropdown(false)
        editLink.source = source;
      } else{
        setShowScreenDropdown(true)
        editLink.source = source;
      }
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }

  const getQuickLinks = async () => {
    try {
      const response = await axios.get("/quick-links/all")
      setQuickLinks(response.data.result)
      setFilteredRecords(response.data.result)
      setCurrentRecords(
        response.data.result.slice(indexOfFirstRecord, indexOfLastRecord)
      )
      setNumberOfPages(Math.ceil(response.data.result.length / recordsPerPage))
    } catch (error) {}
  }

  const editLinkClicked = (link) => {
    setEditLink({ ...link })
    setModalTitle(`Edit Link: ${link.title}`)
    setShowModal(true)
  }

  const deleteLinkClicked = (link) => {
    setDeleteLink({ ...link })
    setModalTitle(`Delete Link: ${link.title}`)
    setShowModal(true)
  }

  const createLinkClicked = (link) => {
    setCreateLink({})
    setModalTitle(`Create Link`)
    setShowModal(true)
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    setSearch(searchValue)
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      // We are searching. Filter the results.
      let records = quicklinks.filter((e) => {
        return e.title.toLowerCase().match(searchString) || e.type.toLowerCase().match(searchString)
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(quicklinks)
      setCurrentRecords(quicklinks.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(quicklinks.length / recordsPerPage))
    }
  }

  const ModalBody = () => {
    if (editLink.id) {
      return <EditLinkElement />
    }

    if (deleteLink.id) {
      return <DeleteLinkElement />
    }

    if (createLink) {
      return <CreateLinkElement />
    }
  }

  const EditLinkElement = () => {
    const updateLink = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.post(
          "/link/update/" + editLink.id,
          editLink
        )
        setQuickLinks(response.data.result)
        setCurrentRecords(response.data.result)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={updateLink}>
        <div className="mb-3">
          <label htmlFor="name" className="block font-medium text-gray-700">
            Title
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={editLink.title}
              required
              type="text"
              name="title"
              id="title"
              onChange={(e) => (createLink.title = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="description"
            className="block font-medium text-gray-700"
          >
            Type
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              defaultValue={editLink.type}
              onChange={(e) => (editLink.type = e.target.value)}
            >
              <option disabled selected>
                Select Type
              </option>
              <option value="External">External</option>
              <option value="Internal">Internal</option>
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="active" className="block font-medium text-gray-700">
            Active
          </label>
          <div className="mt-1 relative rounded-md">
            <select
            defaultValue={editLink.active}
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => (createLink.active = Number(e.target.value))}
            >
              <option disabled selected>
                Select Active
              </option>
              <option value="1">True</option>
              <option value="0">False</option>
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="image" className="block font-medium text-gray-700">
            Source
          </label>
          <div className="mt-1 relative rounded-md">
            {editLink.type == 'external' ? (
            <input
              defaultValue={editLink.source}
              required
              type="text"
              name="source"
              id="source"
              onChange={(e) => toggleSource(editLink.type, e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />) : (
            <select
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              defaultValue={JSON.parse(editLink.meta).screen}
              onChange={(e) => (editLink.meta = {screen: e.target.value})}
            >
              <option disabled selected>
                Select Screen
              </option>
              <option value="PodcastListScreen">Podcast</option>
              <option value="WebinarListScreen">Webinars</option>
            </select>
            )}
          </div>
        </div>
        
        {showScreenDropdown && (
           <div className="mb-3">
          <label
            htmlFor="description"
            className="block font-medium text-gray-700"
          >
            Screen
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              defaultValue={editLink.meta.screen}
              onChange={(e) => (editLink.meta = {screen: e.target.value})}
            >
              <option disabled selected>
                Select Screen
              </option>
              <option value="PodcastListScreen">Podcast</option>
              <option value="WebinarListScreen">Webinars</option>
            </select>
          </div>
        </div>
        )}
        <div className="mb-3">
          <label htmlFor="color" className="block font-medium text-gray-700">
            Primary Color
          </label>
          <div className="mt-1 relative rounded-md">
            <input 
              type="color"
              required
              className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700"
              id="hs-color-input" 
              defaultValue={editLink.primary_color} 
              onChange={(e) => (editLink.primary_color = e.target.value)}
              title="Choose your color" />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="color" className="block font-medium text-gray-700">
            Secondary Color
          </label>
          <div className="mt-1 relative rounded-md">
          <input 
              type="color"
              required
              className="p-1 h-10 w-14 block bg-white border disabled border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700"
              id="hs-color-input" 
              defaultValue={editLink.secondary_color} 
              onChange={(e) => (editLink.secondary_color = e.target.value)}
              title="Choose your color"/>

          </div>
        </div>
        
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Update link</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const CreateLinkElement = () => {
    const addLink = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.post(
          "/quick-links/store",
          createLink
        )
        setQuickLinks(response.data.result)
        setCurrentRecords(response.data.result)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={addLink}>
        <div className="mb-3">
          <label htmlFor="name" className="block font-medium text-gray-700">
            Title
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createLink.title}
              required
              type="text"
              name="title"
              id="title"
              onChange={(e) => (createLink.title = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="description"
            className="block font-medium text-gray-700"
          >
            Type
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => (createLink.type = e.target.value)}
            >
              <option disabled selected>
                Select Type
              </option>
              <option value="External">External</option>
              <option value="Internal">Internal</option>
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="active" className="block font-medium text-gray-700">
            Active
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => (createLink.active = Number(e.target.value))}
            >
              <option disabled selected>
                Select Active
              </option>
              <option value="1">True</option>
              <option value="0">False</option>
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="image" className="block font-medium text-gray-700">
            Source
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createLink.source}
              required
              type="text"
              name="source"
              id="source"
              placeholder="URL"
              onChange={(e) => (createLink.source = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        
        {showScreenDropdown && (
           <div className="mb-3">
          <label
            htmlFor="description"
            className="block font-medium text-gray-700"
          >
            Screen
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => (createLink.meta = {screen: e.target.value})}
            >
              <option disabled selected>
                Select Screen
              </option>
              <option value="PodcastListScreen">Podcast</option>
              <option value="WebinarListScreen">Webinars</option>
            </select>
          </div>
        </div>
        )}
        
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Create link</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const getLinkTypes = async (e) => {
    const response = await axios.get("/link-types/all")
    setLinkTypes(response.data.result)
  }

  const DeleteLinkElement = () => {
    const deleteLinkFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.delete(
          "/quick-links/delete/" + deleteLink.id
        )
        setQuickLinks(response.data.result)
        setCurrentRecords(response.data.result)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={deleteLinkFn}>
        <div className="mb-3">
          <p className="text-xl font-bold my-8 text-center">
            Are you sure you want to delete this link?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Delete link</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const resetModal = (resetQuickLinks = true) => {
    setEditLink({})
    setDeleteLink({})
    setCreateLink({})
    setShowModal(false)
    if (resetQuickLinks) {
      setQuickLinks(quicklinks)
    }
  }

  return (
    <section className="relative">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <MainTitle title="Quick Links" backButton />

      <div className="">
        <ul className="just-between flex-wrap">
          <li className="w-1/4 mb-6 mr-3">
            <div onClick={createLinkClicked}>
              <CardButton icon={faPlus} title="Add Link" />
            </div>
          </li>
        </ul>
      </div>

      <div className="p-4">
        <label htmlFor="table-search">Search</label>
        <div className="relative mt-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch} />
          </div>
          <input
            type="text"
            id="table-search"
            onChange={handleSearch}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search QuickLinks"
          />
        </div>
      </div>

      <table className="min-w-full border text-center bg-white table-auto rounded-xl overflow-hidden">
        <thead className="border-b">
          <tr>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              ID
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Title
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Source
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Type
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Primary Color
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Secondary Color
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Active
            </th>
           {auth.user.role == 1 ? ( <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Actions
            </th>) : null}
          </tr>
        </thead>
        <tbody>
          {currentRecords.map((item) => {
            return (
              <tr key={item.id} className="border-b border-slate-300">
                <td className="border-r border-r-slate-300 p-2">
                  {item.id}
                </td>
                <td className="border-r border-r-slate-300 p-2">
                  {item.title}
                </td>
                <td className="border-r border-r-slate-300 p-2">
                  {item.source}
                </td>
                <td className="border-r border-r-slate-300 p-2">
                  {item.type}
                </td>
                  <td className="border-r border-r-slate-300 p-2">
                  <input 
                      type="color"
                      defaultValue={item.primary_color}
                      className=""
                      disabled/>
                </td>
                <td className="border-r border-r-slate-300 p-2">
                <input 
                      type="color"
                      defaultValue={item.secondary_color}
                      className=""
                      disabled/>
                </td>
                <td className="border-r border-r-slate-300 p-2">
                  {item.active ?  <span className="text-green-400 text-2xl"> <FontAwesomeIcon icon={faCheckCircle} /> </span> : <span className="text-red-500 text-2xl"><FontAwesomeIcon icon={faTimes} /> </span> }
                </td>
                {auth.user.role == 1 ? (<td className="border-r border-r-slate-300 p-2">
                  <div className="flex justify-center">
                    <span
                      onClick={() => editLinkClicked(item)}
                      className="flex justify-center items-center bg-blue-400 rounded-lg text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </span>
                    <span
                      onClick={() => deleteLinkClicked(item)}
                      className="flex justify-center items-center bg-red-400 rounded-lg text-red-800 h-9 w-12 mx-2  hover:bg-red-500 cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </span>
                  </div>
                </td>):null}
              </tr>
            )
          })}
        </tbody>
      </table>
      <Pagination
        next={nextPage}
        prev={previousPage}
        first={indexOfFirstRecord}
        last={indexOfLastRecord}
        total={filteredRecords.length}
      />
    </section>
  )
}

export default QuickLinks
