/* eslint-disable */
import MainTitle from "../../../components/MainTitle"

import { useState, useEffect } from "react"

import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import Modal from "../../../components/Modal"

import CardButton from "../../../components/CardButton"

function Users() {
  const axios = useAxiosPrivate()

  const { setShowLoader, can, auth } = useAuth()

  const [users, setUsers] = useState([])
  const [editUser, setEditUser] = useState({})
  const [createUser, setCreateUser] = useState({})
  const [deleteUser, setDeleteUser] = useState({})
  const [showModal, setShowModal] = useState(false)

  const [userTypes, setUserTypes] = useState([])

  const [modalTitle, setModalTitle] = useState("")

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setShowLoader(true)
    await Promise.all([getUsers()])
    setShowLoader(false)
  }

  const getUsers = async () => {
    try {
      const response = await axios.get("/users/all")
      setUsers(response.data.result)
    } catch (error) {}
  }

  const editUserClicked = (user) => {
    setEditUser({ ...user })
    setModalTitle(`Edit User: ${user.name}`)
    setShowModal(true)
  }

  const deleteUserClicked = (user) => {
    setDeleteUser({ ...user })
    setModalTitle(`Delete User: ${user.name}`)
    setShowModal(true)
  }

  const createUserClicked = (user) => {
    setCreateUser({})
    setModalTitle(`Create User`)
    setShowModal(true)
  }

  const ModalBody = () => {
    if (editUser.id) {
      return <EditUserElement />
    }

    if (deleteUser.id) {
      return <DeleteUserElement />
    }

    if (createUser) {
      return <CreateUserElement />
    }
  }

  const EditUserElement = () => {
    const updateUser = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const data = {
          name: editUser.name,
          email: editUser.email,
          type_id: editUser.type_id,
          phone_number: editUser.phone_number,
          date_of_birth: editUser.date_of_birth,
        }
        const response = await axios.post(
          "/users/update/" + editUser.id + "?filter=all",
          data
        )
        setUsers(response.data.result)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={updateUser}>
        <div className="mb-3">
          <label htmlFor="name" className="block font-medium text-gray-700">
            Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={editUser.name}
              type="text"
              name="name"
              id="name"
              onChange={(e) => (editUser.name = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="block font-medium text-gray-700">
            Email
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={editUser.email}
              type="email"
              name="email"
              id="email"
              onChange={(e) => (editUser.email = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="phone_number"
            className="block font-medium text-gray-700"
          >
            Phone Number
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={editUser.phone_number}
              type="string"
              required
              name="phone_number"
              id="phone_number"
              onChange={(e) => (editUser.phone_number = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="phone_number"
            className="block font-medium text-gray-700"
          >
            Date of Birth
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={editUser.date_of_birth}
              type="date"
              required
              name="date_of_birth"
              id="date_of_birth"
              onChange={(e) => (editUser.date_of_birth = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Update User</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const CreateUserElement = () => {
    const addUser = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.post(
          "/users/create",
          createUser
        )
        setUsers([...users, response.data.result])
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={addUser}>
        <div className="mb-3">
          <label htmlFor="name" className="block font-medium text-gray-700">
            Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createUser.name}
              required
              type="text"
              name="name"
              id="name"
              onChange={(e) => (createUser.name = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="block font-medium text-gray-700">
            Email
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createUser.email}
              type="email"
              required
              name="email"
              id="email"
              onChange={(e) => (createUser.email = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="phone_number"
            className="block font-medium text-gray-700"
          >
            Phone Number
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createUser.phone_number}
              type="string"
              required
              name="phone_number"
              id="phone_number"
              onChange={(e) => (createUser.phone_number = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="phone_number"
            className="block font-medium text-gray-700"
          >
            Date of Birth
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createUser.date_of_birth}
              type="date"
              required
              name="date_of_birth"
              id="date_of_birth"
              onChange={(e) => (createUser.date_of_birth = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Create User</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const getUserTypes = async (e) => {
    const response = await axios.get("/user-types/all")
    setUserTypes(response.data.result)
  }

  const DeleteUserElement = () => {
    const deleteUserFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.get(
          "/users/delete/" + deleteUser.id + "?filter=all"
        )
        setUsers(response.data.result)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={deleteUserFn}>
        <div className="mb-3">
          <p className="text-xl font-bold my-8 text-center">
            Are you sure you want to delete this user?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Delete User</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const resetModal = (resetUsers = true) => {
    setEditUser({})
    setDeleteUser({})
    setCreateUser({})
    setShowModal(false)
    if (resetUsers) {
      setUsers(users)
    }
  }

  return (
    <section className="relative">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <MainTitle title="Users" backButton />

      <div>
        <ul className="flex just-between flex-wrap">
          <li className="w-1/4 mb-6 mr-3">
            <div onClick={createUserClicked}>
              <CardButton icon={faPlus} title="Add User" />
            </div>
          </li>
        </ul>
      </div>

      <table className="min-w-full border text-center bg-white table-auto rounded-xl overflow-hidden">
        <thead className="border-b">
          <tr>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              ID
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Name
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Email
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              User Type
            </th>
            {auth.user.role === 1 ? ( <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Actions
            </th>) : null}
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            return (
              <tr key={user.id} className="border-b border-slate-300">
                <td className="border-r border-r-slate-300 p-2">{user.id}</td>
                <td className="border-r border-r-slate-300 p-2">{user.name}</td>
                <td className="border-r border-r-slate-300 p-2">
                  {user.email}
                </td>
                <td className="border-r border-r-slate-300 p-2">{user.role == 1 ? 'Super User' : 'Admin'}</td>
                {auth.user.role === 1 ? ( <td className="border-r border-r-slate-300 p-2">
                  <div className="flex justify-center">
                    <span
                      onClick={() => editUserClicked(user)}
                      className="flex justify-center items-center bg-blue-400 rounded-lg text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </span>
                    <span
                      onClick={() => deleteUserClicked(user)}
                      className="flex justify-center items-center bg-red-400 rounded-lg text-red-800 h-9 w-12 mx-2  hover:bg-red-500 cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </span>
                  </div>
                </td>): null}
              </tr>
            )
          })}
        </tbody>
      </table>
    </section>
  )
}

export default Users
