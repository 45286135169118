import logo from '../img/swb-logo.png'
import { Link, useNavigate } from 'react-router-dom'

function Missing() {
  const navigate = useNavigate()

  return (
    <section className='w-screen h-screen py-16'>
      <div className='main-bg'>&nbsp;</div>
      <div className='flex items-center flex-col z-10 relative'>
        <div className='flex'>
          <img src={logo} alt='The Beauty Group Logo' className='w-72' />
        </div>
        <div className='bg-white rounded-xl shadow-2xl py-10 mt-8 px-8 w-full flex flex-col items-center' style={{ maxWidth: 500 }}>
          <h1 className='text-3xl text-wba-primary font-bold text-center'>404 Page Not Found</h1>
          <h2 className='text-center my-8'>
            Uh oh! We can't seem to find the page you're looking for. Try going back to the previous page or see our
            <Link to='/help'>Help Center</Link> for more information.
          </h2>
          <button onClick={() => navigate(-1, { replace: true })} className='btn primary w-full'>
            Go Back
          </button>
        </div>
      </div>
    </section>
  )
}

export default Missing
