/* eslint-disable */
import MainTitle from "../../components/MainTitle"

import { useState, useEffect, useRef } from "react"

import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useAuth from "../../hooks/useAuth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CardButton from "../../components/CardButton"
import Pagination from "../../components/Pagination"
import {
  faPencil,
  faTrashCan,
  faPlus,
  faTimes,
  faCheckCircle,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons"
import Modal from "../../components/Modal"

function PushNotifications() {
  const axios = useAxiosPrivate()

  const { setShowLoader, can, auth } = useAuth()
  const [notifications, setNotifications] = useState([])
  const [editNotification, setEditNotification] = useState({})
  const [createNotification, setCreateNotification] = useState({})
  const [deleteNotification, setDeleteNotification] = useState({})
  const [showModal, setShowModal] = useState(false)
  // const notificationRef = useRef()

  const [search, setSearch] = useState("")

  const [currentRecords, setCurrentRecords] = useState([])
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  // User is currently on this page
  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  const [modalTitle, setModalTitle] = useState("")

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setShowLoader(true)
    await Promise.all([getNotifications()])
    setShowLoader(false)
  }

  const getNotifications = async () => {
    try {
      const response = await axios.get("/push-notifications")
      setNotifications(response.data.result)
      setFilteredRecords(response.data.result)
      setCurrentRecords(
        response.data.result.slice(indexOfFirstRecord, indexOfLastRecord)
      )
      setNumberOfPages(Math.ceil(response.data.result.length / recordsPerPage))
    } catch (error) {}
  }

  const editNotificationClicked = (notification) => {
    setEditNotification({ ...notification })
    setModalTitle(`Edit Notification: ${notification.title}`)
    setShowModal(true)
  }

  const deleteNotificationClicked = (notification) => {
    setDeleteNotification({ ...notification })
    setModalTitle(`Delete Notification: ${notification.title}`)
    setShowModal(true)
  }

  const createNotificationClicked = (notification) => {
    setCreateNotification({})
    setModalTitle(`Create Notification`)
    setShowModal(true)
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    setSearch(searchValue)
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      // We are searching. Filter the results.
      let records = notifications.filter((e) => {
        return e.title.toLowerCase().match(searchString)
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(notifications)
      setCurrentRecords(
        notifications.slice(indexOfFirstRecord, indexOfLastRecord)
      )
      setNumberOfPages(Math.ceil(notifications.length / recordsPerPage))
    }
  }

  const ModalBody = () => {
    if (editNotification.id) {
      return <EditNotificationElement />
    }

    if (deleteNotification.id) {
      return <DeleteNotificationElement />
    }

    if (createNotification) {
      return <CreateNotificationElement />
    }
  }

  const EditNotificationElement = () => {
    const updateNotification = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const data = {
          title: editNotification.name,
          type_id: editNotification.type_id,
          body: editNotification.body,
        }
        const response = await axios.post(
          "/notifications/update/" + editNotification.id + "?filter=all",
          data
        )
        setNotifications(response.data.result)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={updateNotification}>
        <div className="mb-3">
          <label htmlFor="name" className="block font-medium text-gray-700">
            Title
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={editNotification.title}
              type="text"
              name="title"
              id="title"
              onChange={(e) => (editNotification.title = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="description"
            className="block font-medium text-gray-700"
          >
            Body
          </label>
          <div className="mt-1 relative rounded-md">
            <textarea
              defaultValue={editNotification.body}
              type="text"
              name="body"
              id="body"
              onChange={(e) => (editNotification.body = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Update</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const CreateNotificationElement = () => {
    const addNotification = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.post(
          "/push-notification/test?filter=all",
          createNotification
        )
        // window.location.reload();
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={addNotification}>
        <div className="mb-3">
          <label htmlFor="title" className="block font-medium text-gray-700">
            Title
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createNotification.title}
              required
              type="text"
              name="title"
              id="title"
              onChange={(e) => (createNotification.title = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="body" className="block font-medium text-gray-700">
            Body
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createNotification.body}
              required
              type="text"
              name="body"
              id="body"
              onChange={(e) => (createNotification.body = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="visible" className="block font-medium text-gray-700">
            Visible
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              defaultValue={createNotification.visible}
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => (createNotification.visible = e.target.value)}
            >
              <option disabled selected>
                Select Visibility
              </option>
              <option value={1}>True</option>
              <option value={0}>False</option>
            </select>
          </div>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">
            Create Push Notification
          </button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const getNotificationTypes = async (e) => {
    const response = await axios.get("/notification-types/all")
    setNotificationTypes(response.data.result)
  }

  const DeleteNotificationElement = () => {
    const deleteNotificationFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.get(
          "/push-notification/delete/" + deleteNotification.id + "?filter=all"
        )
        window.location.reload()
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={deleteNotificationFn}>
        <div className="mb-3">
          <p className="text-xl font-bold my-8 text-center">
            Are you sure you want to delete this notification?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">
              Delete Notification
            </button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const resetModal = (resetNotifications = true) => {
    setEditNotification({})
    setDeleteNotification({})
    setCreateNotification({})
    setShowModal(false)
    if (resetNotifications) {
      setNotifications(notifications)
    }
  }

  return (
    <section className="relative">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <MainTitle title="Push Notifications" backButton />

      <div className="">
        <ul className="just-between flex-wrap">
          <li className="w-1/4 mb-6 mr-3">
            <div onClick={createNotificationClicked}>
              <CardButton icon={faPlus} title="Add Push Notification" />
            </div>
          </li>
        </ul>
      </div>

      <div className="p-4">
        <label htmlFor="table-search">Search</label>
        <div className="relative mt-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch} />
          </div>
          <input
            type="text"
            id="table-search"
            onChange={handleSearch}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search Push Notifications"
          />
        </div>
      </div>

      <table className="min-w-full border text-center bg-white table-auto rounded-xl overflow-hidden">
        <thead className="border-b">
          <tr>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              ID
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Title
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Body
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Visible
            </th>
            {auth.user.role == 1 ? (<th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Actions
            </th>) : null }
          </tr>
        </thead>
        {currentRecords.length > 0 ? (
          <tbody>
            {currentRecords.map((notification) => {
              return (
                <tr key={notification.id} className="border-b border-slate-300">
                  <td className="border-r border-r-slate-300 p-2">
                    {notification.id}
                  </td>
                  <td className="border-r border-r-slate-300 p-2">
                    {notification.title}
                  </td>
                  <td className="border-r border-r-slate-300 p-2">
                    {notification.body}
                  </td>
                  <td className="border-r border-r-slate-300 p-2">
                    {notification.visible ? <span className="text-green-400 text-2xl"> <FontAwesomeIcon icon={faCheckCircle} /> </span> : <span className="text-red-500 text-2xl"><FontAwesomeIcon icon={faTimes} /> </span> }
                  </td>
                  <td className="border-r border-r-slate-300 p-2">
                    <div className="flex justify-center">
                      {auth.user.role === 1 ? (
                        <span
                          onClick={() => editNotificationClicked(notification)}
                          className="flex justify-center items-center bg-blue-400 rounded-lg text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer"
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </span>
                      ) : null}

                      {auth.user.role === 1 ? (<span
                        onClick={() => deleteNotificationClicked(notification)}
                        className="flex justify-center items-center bg-red-400 rounded-lg text-red-800 h-9 w-12 mx-2  hover:bg-red-500 cursor-pointer"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </span> ) : null }
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        ) : (
          <tr>
            <td colSpan={5} className="text-center py-2">
              No Records
            </td>
          </tr>
        )}
      </table>
      <Pagination
        next={nextPage}
        prev={previousPage}
        first={indexOfFirstRecord}
        last={indexOfLastRecord}
        total={filteredRecords.length}
      />
    </section>
  )
}

export default PushNotifications
