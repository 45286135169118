/* eslint-disable */
import MainTitle from "../../components/MainTitle"

import { useState, useEffect, useRef } from "react"
import CardButton from "../../components/CardButton"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useAuth from "../../hooks/useAuth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Pagination from "../../components/Pagination"
import {
  faPencil,
  faTrashCan,
  faPlus,
  faMagnifyingGlass,
  faCheckCircle,
  faTimes
} from "@fortawesome/free-solid-svg-icons"
import Modal from "../../components/Modal"

function Services() {
  const axios = useAxiosPrivate()

  const { setShowLoader, auth } = useAuth()

  const [services, setServices] = useState([])
  const [editService, setEditService] = useState({})
  const [createService, setCreateService] = useState({})
  const [deleteService, setDeleteService] = useState({})
  const [showModal, setShowModal] = useState(false)
  const serviceRef = useRef()

  const [currentRecords, setCurrentRecords] = useState([])
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  // User is currently on this page
  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [search, setSearch] = useState("")

  const [modalTitle, setModalTitle] = useState("")

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setShowLoader(true)
    await Promise.all([getServices()])
    setShowLoader(false)
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }

  const getServices = async () => {
    try {
      const response = await axios.get("/services")
      setServices(response.data.result)
      setFilteredRecords(response.data.result)
      setCurrentRecords(
        response.data.result.slice(indexOfFirstRecord, indexOfLastRecord)
      )
      setNumberOfPages(Math.ceil(response.data.result.length / recordsPerPage))
    } catch (error) {}
  }

  const editServiceClicked = (service) => {
    setEditService({ ...service })
    console.log(service)
    setModalTitle(`Edit Service: ${service.name}`)
    setShowModal(true)
  }

  const deleteServiceClicked = (service) => {
    setDeleteService({ ...service })
    setModalTitle(`Delete Service: ${service.name}`)
    setShowModal(true)
  }

  const createServiceClicked = (service) => {
    setCreateService({})
    setModalTitle(`Create Service`)
    setShowModal(true)
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    setSearch(searchValue)
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      // We are searching. Filter the results.
      let records = services.filter((e) => {
        return e.name.toLowerCase().match(searchString) || e.type.toLowerCase().match(searchString)
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(services)
      setCurrentRecords(services.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(services.length / recordsPerPage))
    }
  }

  const ModalBody = () => {
    if (editService.id) {
      return <EditServiceElement />
    }

    if (deleteService.id) {
      return <DeleteServiceElement />
    }

    if (createService) {
      return <CreateServiceElement />
    }
  }

  const EditServiceElement = () => {
    const updateService = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.post(
          "/service/update/" + editService.id,
          editService
        )
        setServices(response.data.result)
        setCurrentRecords(response.data.result)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={updateService}>
        <div className="mb-3">
          <label htmlFor="name" className="block font-medium text-gray-700">
            Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={editService.name}
              type="text"
              name="name"
              id="name"
              onChange={(e) => (editService.name = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="image" className="block font-medium text-gray-700">
            Image
          </label>
          <div className="mt-1 relative rounded-md items-center">
            <img
              src={editService.image_url}
              type="image"
              name="image"
              id="image"
              ref={serviceRef}
              onChange={(e) => (editService.image_url = e.target.value)}
              className="px-4 py-2 block w-1/3 border border-gray-300 rounded-md shadow-md"
            />
            <div className="items-center">
              <label
                htmlFor="image"
                className="btn primary mt-2 w-1/3"
                onClick={(e) => serviceRef.current.focus()}
              >
                Change Photo
              </label>
            </div>
          </div>
        </div>
        
        <div className="mb-3">
          <label
            htmlFor="description"
            className="block font-medium text-gray-700"
          >
            Active
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="checkbox"
              checked={editService.active}
              name="active"
              id="active"
              onChange={(e) => (editService.active = e.target.value)}
              className="px-4 py-2 block"
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="description"
            className="block font-medium text-gray-700"
          >
            Type
          </label>
          <div className="mt-1 relative rounded-md">
          <div className="mb-3">
          <select
            defaultValue={editService.type}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => (editService.type = e.target.value)}
          >
            <option disabled selected>
              Select Service Type
            </option>
            <option value="staff">Staff</option>
            <option value="staff">Pupil</option>
            {/* {statusTypes?.map((type) => {
                // if (can(`create ${type.title}`)) {
                  return <option value={type.id}>{type.title}</option>;
                // }
              })} */}
          </select>
        </div>
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="description"
            className="block font-medium text-gray-700"
          >
            Description
          </label>
          <div className="mt-1 relative rounded-md">
            <textarea
              defaultValue={editService.description}
              type="text"
              name="description"
              id="description"
              onChange={(e) => (editService.description = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Update Service</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const CreateServiceElement = () => {
    const addService = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.post(
          "/service/create?filter=all",
          createService
        )
        setServices(response.data.result)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={addService}>
        <div className="mb-3">
          <label htmlFor="name" className="block font-medium text-gray-700">
            Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createService.name}
              required
              type="text"
              name="name"
              id="name"
              onChange={(e) => (createService.name = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="image" className="block font-medium text-gray-700">
            Image URL
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createService.image_url}
              required
              type="text"
              name="image"
              id="image"
              onChange={(e) => (createService.image_url = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="description"
            className="block font-medium text-gray-700"
          >
            Description
          </label>
          <div className="mt-1 relative rounded-md">
            <textarea
              value={createService.description}
              required
              type="text"
              name="description"
              id="description"
              onChange={(e) => (createService.description = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="color" className="block font-medium text-gray-700">
            Color
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createService.color}
              required
              type="text"
              name="color"
              id="color"
              onChange={(e) => (createService.color = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="active" className="block font-medium text-gray-700">
            Active
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => (createService.active = Number(e.target.value))}
            >
              <option disabled selected>
                Select Active
              </option>
              <option value="1">True</option>
              <option value="0">False</option>
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="tpye" className="block font-medium text-gray-700">
            Type
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => (createService.type = Number(e.target.value))}
            >
              <option disabled selected>
                Select Type
              </option>
              <option value="Staff">Staff</option>
              <option value="Pupil">Pupil</option>
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="button_text"
            className="block font-medium text-gray-700"
          >
            Button Text
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createService.button_text}
              required
              type="text"
              name="button_text"
              id="button_text"
              onChange={(e) => (createService.button_text = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Create Service</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const getServiceTypes = async (e) => {
    const response = await axios.get("/service-types/all")
    setServiceTypes(response.data.result)
  }

  const DeleteServiceElement = () => {
    const deleteServiceFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.get(
          "/service/delete/" + deleteService.id + "?filter=all"
        )
        setServices(response.data.result)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={deleteServiceFn}>
        <div className="mb-3">
          <p className="text-xl font-bold my-8 text-center">
            Are you sure you want to delete this service?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Delete Service</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const resetModal = (resetServices = true) => {
    setEditService({})
    setDeleteService({})
    setCreateService({})
    setShowModal(false)
    if (resetServices) {
      setServices(services)
    }
  }

  return (
    <section className="relative">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <MainTitle title="Services" backButton />

      <div className="">
        <ul className="just-between flex-wrap">
          <li className="w-1/4 mb-6 mr-3">
            <div onClick={createServiceClicked}>
              <CardButton icon={faPlus} title="Add Service" />
            </div>
          </li>
        </ul>
      </div>

      <div className="p-4">
        <label htmlFor="table-search">Search</label>
        <div className="relative mt-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch} />
          </div>
          <input
            type="text"
            id="table-search"
            onChange={handleSearch}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search Services"
          />
        </div>
      </div>

      <table className="min-w-full border text-center bg-white table-auto rounded-xl overflow-hidden">
        <thead className="border-b">
          <tr>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              ID
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Name
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Image
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Description
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Type
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Active
            </th>
           {auth.user.role == 1 ? ( <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Actions
            </th>) : null}
          </tr>
        </thead>
        <tbody>
          {currentRecords.map((service) => {
            return (
              <tr key={service.id} className="border-b border-slate-300">
                <td className="border-r border-r-slate-300 p-2">
                  {service.id}
                </td>
                <td className="border-r border-r-slate-300 p-2">
                  {service.name}
                </td>
                <td className="border-r border-r-slate-300 p-2">
                  <div className="flex justify-center">
                    <img
                      src={service.image_url}
                      alt={service.name}
                      className="w-1/2"
                    />
                  </div>
                </td>
                <td className="border-r border-r-slate-300 p-2">
                  {service.description}
                </td>
                <td className="border-r border-r-slate-300 p-2">
                  {service.type}
                </td>
                <td className="border-r border-r-slate-300 p-2">
                  {service.active ?  <span className="text-green-400 text-2xl"> <FontAwesomeIcon icon={faCheckCircle} /> </span> : <span className="text-red-500 text-2xl"><FontAwesomeIcon icon={faTimes} /> </span> }
                </td>
                {auth.user.role == 1 ? (<td className="border-r border-r-slate-300 p-2">
                  <div className="flex justify-center">
                    <span
                      onClick={() => editServiceClicked(service)}
                      className="flex justify-center items-center bg-blue-400 rounded-lg text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </span>
                    <span
                      onClick={() => deleteServiceClicked(service)}
                      className="flex justify-center items-center bg-red-400 rounded-lg text-red-800 h-9 w-12 mx-2  hover:bg-red-500 cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </span>
                  </div>
                </td>):null}
              </tr>
            )
          })}
        </tbody>
      </table>
      <Pagination
        next={nextPage}
        prev={previousPage}
        first={indexOfFirstRecord}
        last={indexOfLastRecord}
        total={filteredRecords.length}
      />
    </section>
  )
}

export default Services
