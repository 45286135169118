import MainTitle from "../../components/MainTitle"

function Accounts() {
  return (
    <section>
      <MainTitle title="Accounts"/>
    </section>
  )
}

export default Accounts