/* eslint-disable */
import MainTitle from "../../components/MainTitle"

import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import moment from 'moment'
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useAuth from "../../hooks/useAuth"
import Pagination from "../../components/Pagination"
import Search from "../../components/Search"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEye,
  faPencil,
  faTrashCan,
  faArrowRight,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons"
import Modal from "../../components/Modal"

function Enquirys() {
  const axios = useAxiosPrivate()

  const { setShowLoader, can, auth } = useAuth()

  const [enquiries, setEnquirys] = useState([])
  const [editEnquiry, setEditEnquiry] = useState({})
  const [createEnquiry, setCreateEnquiry] = useState({})

  const [deleteEnquiry, setDeleteEnquiry] = useState({})
  const [showModal, setShowModal] = useState(false)

  const [modalTitle, setModalTitle] = useState("")

  const [search, setSearch] = useState("")

  const [currentRecords, setCurrentRecords] = useState([])
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  // User is currently on this page
  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  // Records to be displayed on the current page

  const navigate = useNavigate()

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setShowLoader(true)
    await Promise.all([getEnquirys()])
    setShowLoader(false)
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    setSearch(searchValue)
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      // We are searching. Filter the results.
      let records = enquiries.filter((e) => {
        return JSON.parse(e.info).user.name.toLowerCase().match(searchString) || JSON.parse(e.info).user.email.toLowerCase().match(searchString) || e.created_at.toLowerCase().match(searchString) || e.reference.toLowerCase().match(searchString)
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(enquiries)
      setCurrentRecords(enquiries.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(enquiries.length / recordsPerPage))
    }
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }

  const getEnquirys = async () => {
    try {
      const response = await axios.get("/enquiries")
      setEnquirys(response.data.result)
      setFilteredRecords(response.data.result)
      setCurrentRecords(
        response.data.result.slice(indexOfFirstRecord, indexOfLastRecord)
      )
      setNumberOfPages(Math.ceil(response.data.result.length / recordsPerPage))
    } catch (error) {}
  }

  const editEnquiryClicked = (enquiry) => {
    setEditEnquiry({ ...enquiry })
    setModalTitle(`Edit Enquiry: ${enquiry.reference}`)
    setShowModal(true)
  }

  const deleteEnquiryClicked = (enquiry) => {
    setDeleteEnquiry({ ...enquiry })
    setModalTitle(`Delete Enquiry: ${enquiry.reference}`)
    setShowModal(true)
  }

  const viewEnquiryClicked = (enquiry) => {
    navigate("/enquiry/" + enquiry.id)
  }

  const createEnquiryClicked = (enquiry) => {
    setCreateEnquiry({})
    setModalTitle(`Create Enquiry`)
    setShowModal(true)
  }

  const ModalBody = () => {
    if (editEnquiry.id) {
      return <EditEnquiryElement />
    }

    if (deleteEnquiry.id) {
      return <DeleteEnquiryElement />
    }

    if (createEnquiry) {
      return <CreateEnquiryElement />
    }
  }

  const EditEnquiryElement = () => {
    const updateEnquiry = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const data = {
          name: editEnquiry.name,
          type_id: editEnquiry.type_id,
        }
        const response = await axios.post(
          "/enquiry/update/" + editEnquiry.id + "?filter=all",
          data
        )
        setEnquirys(response.data.result)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={updateEnquiry}>
        <div className="mb-3">
          <label htmlFor="name" className="block font-medium text-gray-700">
            Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={JSON.parse(editEnquiry.info).user.name}
              type="text"
              name="name"
              id="name"
              disabled
              onChange={(e) =>
                (JSON.parse(editEnquiry.info).user.name = e.target.value)
              }
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="block font-medium text-gray-700">
            Email
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={JSON.parse(editEnquiry.info).user.email}
              type="email"
              name="email"
              id="email"
              disabled
              onChange={(e) =>
                (JSON.parse(editEnquiry.info).user.email = e.target.value)
              }
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="status" className="block font-medium text-gray-700">
            Status
          </label>
          <select
            defaultValue={editEnquiry.status}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => (editEnquiry.status = Number(e.target.value))}
          >
            <option disabled selected>
              Select Enquiry Status
            </option>
            {/* {statusTypes?.map((type) => {
                // if (can(`create ${type.title}`)) {
                  return <option value={type.id}>{type.title}</option>;
                // }
              })} */}
          </select>
        </div>

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Update Enquiry</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const CreateEnquiryElement = () => {
    const addEnquiry = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.post(
          "/enquiry/create?filter=all",
          createEnquiry
        )
        setEnquirys(response.data.result)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={addEnquiry}>
        <div className="mb-3">
          <label htmlFor="info" className="block font-medium text-gray-700">
            Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createEnquiry.info.name}
              required
              type="text"
              name="name"
              id="name"
              onChange={(e) => (createEnquiry.info.name = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="block font-medium text-gray-700">
            Email
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createEnquiry.info.email}
              required
              type="text"
              name="email"
              id="email"
              onChange={(e) => (createEnquiry.info.email = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="phone_number"
            className="block font-medium text-gray-700"
          >
            Phone Number
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createEnquiry.info.phone_number}
              required
              type="number"
              name="phone_number"
              id="phone_number"
              onChange={(e) =>
                (createEnquiry.info.phone_number = e.target.value)
              }
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="schoolName"
            className="block font-medium text-gray-700"
          >
            School Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createEnquiry.info.schoolName}
              required
              type="number"
              name="schoolName"
              id="schoolName"
              onChange={(e) => (createEnquiry.info.schoolName = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="schoolPostcode"
            className="block font-medium text-gray-700"
          >
            School Postcode
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createEnquiry.info.schoolPostcode}
              required
              type="number"
              name="schoolPostcode"
              id="schoolPostcode"
              onChange={(e) =>
                (createEnquiry.info.schoolPostcode = e.target.value)
              }
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Create Enquiry</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const getEnquiryTypes = async (e) => {
    const response = await axios.get("/enquiry-types/all")
    setEnquiryTypes(response.data.result)
  }

  const DeleteEnquiryElement = () => {
    const deleteEnquiryFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.get(
          "/enquiry/delete/" + deleteEnquiry.id + "?filter=all"
        )
        setEnquirys(response.data.result)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={deleteEnquiryFn}>
        <div className="mb-3">
          <p className="text-xl font-bold my-8 text-center">
            Are you sure you want to delete this enquiry?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Delete Enquiry</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const resetModal = (resetEnquirys = true) => {
    setEditEnquiry({})
    setDeleteEnquiry({})
    setCreateEnquiry({})
    setShowModal(false)
    if (resetEnquirys) {
      setEnquirys(enquiries)
    }
  }

  return (
    <section className="relative">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <MainTitle title="Enquirys" backButton />

      <div className="p-4">
        <label htmlFor="table-search">Search</label>
        <div className="relative mt-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch} />
          </div>
          <input
            type="text"
            id="table-search"
            onChange={handleSearch}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search Enquiries"
          />
        </div>
      </div>

      <table className="min-w-full border text-center bg-white table-auto rounded-xl overflow-hidden">
        <thead className="border-b">
          <tr>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              ID
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Name
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Email
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Reference
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Status
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Created At
            </th>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {currentRecords.map((enquiry) => {
            return (
              <tr key={enquiry.id} className="border-b border-slate-300">
                <td className="border-r border-r-slate-300 p-2">
                  {enquiry.id}
                </td>
                <td className="border-r border-r-slate-300 p-2">
                  {JSON.parse(enquiry.info).user.name}
                </td>
                <td className="border-r border-r-slate-300 p-2">
                  {JSON.parse(enquiry.info).user.email}
                </td>
                <td className="border-r border-r-slate-300 p-2">
                  {enquiry.reference}
                </td>
                <td className="border-r border-r-slate-300 p-2">
                  {enquiry.status}
                </td>
                <td className="border-r border-r-slate-300 p-2">
                {moment(enquiry.created_at).format('YYYY/MM/DD')}
                </td>
                <td className="border-r border-r-slate-300 p-2">
                  <div className="flex justify-center">
                    <span
                      onClick={() => viewEnquiryClicked(enquiry)}
                      className="flex justify-center items-center bg-yellow-400 rounded-lg text-yellow-800 h-9 w-12 mx-2  hover:bg-yellow-500 cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </span>
                   {/* {auth.user.role == 1 ? ( <span
                      onClick={() => editEnquiryClicked(enquiry)}
                      className="flex justify-center items-center bg-blue-400 rounded-lg text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </span>) : null} */}

                    {auth.user.role == 1 ? ( <span
                      onClick={() => deleteEnquiryClicked(enquiry)}
                      className="flex justify-center items-center bg-red-400 rounded-lg text-red-800 h-9 w-12 mx-2  hover:bg-red-500 cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </span>) : null}
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <Pagination
        next={nextPage}
        prev={previousPage}
        first={indexOfFirstRecord}
        last={indexOfLastRecord}
        total={filteredRecords.length}
      />
    </section>
  )
}

export default Enquirys
