import { Outlet } from 'react-router-dom'
import { useState, useEffect } from 'react'
import useRefreshData from '../hooks/useRefreshData'
import useAuth from '../hooks/useAuth'
import MainLoader from './MainLoader'

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true)
  const refresh = useRefreshData()
  const { auth } = useAuth()

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  useEffect(() => {
    let isMounted = true
    const refreshAuth = async () => {
      try {
        await refresh()
      } catch (error) {
        console.log(error)
      } finally {
        await delay(250)
        isMounted && setIsLoading(false)
      }
    }
    !auth.token ? refreshAuth() : setIsLoading(false)

    return () => (isMounted = false)
  }, [auth.token, refresh])

  useEffect(() => {}, [isLoading])

  return <>{isLoading ? <MainLoader /> : <Outlet />}</>
}

export default PersistLogin
