import MainTitle from "../../components/MainTitle"

function Dashboard() {
  return (
    <section>
      <MainTitle title="Dashboard"/>
    </section>
  )
}

export default Dashboard