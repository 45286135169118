/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react'
import { faCircleExclamation, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from 'react-router-dom'

import axios from '../api/axios'

const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,12})$/
const FORGOT_PWD_URL = '/forgot-password'

const ForgotPassword = () => {

  const emailRef = useRef()
  const errRef = useRef()

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  // const [success, setSuccess] = useState(false)

  useEffect(() => {
    emailRef.current.focus()
  }, [])

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])

  useEffect(() => {
    setErrMsg('')
  }, [email,])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const v1 = EMAIL_REGEX.test(email)
    if (!v1) {
      setErrMsg('Invalid Entry')
      return
    }

    try {
      await axios.post(FORGOT_PWD_URL, { email })
      setEmail('')
      // setSuccess(true);

    } catch (error) {
      if (!error?.response) {
        setErrMsg('No Server Response')
      } else if (error.response?.status === 422) {
        setErrMsg('The email provided already exists.')
      } else {
        setErrMsg('Error Occured. Could not create account.')
      }
      errRef.current.focus()
    }
  }

  return (
            <div>
              <h1 className='text-3xl text-wba-primary font-bold text-center mb-6'>Forgot your Password?</h1>
              <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live='assertive'>
                {errMsg}
              </p>
              <form onSubmit={handleSubmit}>

                <div className='input-wrap'>
                  <input type='email' id='email' ref={emailRef} onChange={(e) => setEmail(e.target.value)} required aria-invalid={validEmail ? 'false' : 'true'} aria-describedby='emailnote' placeholder='Email' onFocus={() => setEmailFocus(true)} onBlur={() => setEmailFocus(false)} className='field' />
                  <label htmlFor='email'>Email</label>
                  <span className={validEmail ? 'valid input-check' : 'hide input-check'}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className={validEmail || !email ? 'hide input-check' : 'invalid input-check'}>
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                  <p id='emailnote' className={emailFocus && email && !validEmail ? 'instructions' : 'offscreen'}>
                    <FontAwesomeIcon icon={faCircleExclamation} />
                    Invalid email address.
                    <br /> Valid email addresses can contain only letters, numbers, '@' and '.'
                  </p>
                </div>


                <div className='input-wrap'>
                  <input type='submit' disabled={!validEmail ? true : false} value='Reset Password' />
                </div>
              </form>

              <div className='mt-4 text-wba-primary text-xs flex justify-center'>
                <Link to='/login' className='hover:text-blue-800'>
                  Back to Login
                </Link>
              </div>
            </div>
  )
}

export default ForgotPassword
