import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useAuth from "../hooks/useAuth"

function InfoMessage() {
  
  const {showInfo} = useAuth()

  return (
    <div className={`floating-info ${showInfo.type} ${showInfo.show}`}>
      <span className="icon">
        {showInfo.type === 'error' ? (
          <FontAwesomeIcon icon={faCircleExclamation} />
        ) : (
          <FontAwesomeIcon  icon={faCircleExclamation} />
        )}
      </span>
      
      <p className="text" style={{width: 'calc(100% - 65px)'}}>
       {showInfo.message}

      </p>
    </div>
   
  )
}
export default InfoMessage
