import MainTitle from "../../components/MainTitle"

import { NavLink } from "react-router-dom"
import CardButton from "../../components/CardButton"

import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons"

function Settings() {
  // eslint-disable-next-line no-unused-vars
  const viewUsers = () => {
    return window.location("/settings/users")
  }

  return (
    <section>
      <MainTitle title="Settings" />
      <ul className="flex just-between flex-wrap">
        <li className="w-1/4 mb-6 mr-3">
          <NavLink to="users">
            <CardButton icon={faPeopleGroup} title="Users" />
          </NavLink>
        </li>
      </ul>
    </section>
  )
}

export default Settings
