import { Outlet } from 'react-router-dom'

import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import Loader from '../components/Loader';
import useAuth from '../hooks/useAuth';
import InfoMessage from '../components/InfoMessage';


function MainLayout() {
  const {showLoader, showInfo} = useAuth();
  return (
    <div className='relative flex min-h-screen bg-slate-100'>
      <LeftBar />
      <main className='p-4 md:pl-0 w-full' style={{marginLeft: '20rem'}}>
        <Header />
        <div className='mt-20'>
        <Outlet/>

        </div>
      </main>
      {showLoader && 
      <Loader/>
      }
      {showInfo?.message && 
      <InfoMessage/>
      }
    </div>
  )
}

export default MainLayout
