import MainTitle from "../../components/MainTitle"

function Tools() {
  return (
    <section>
      <MainTitle title="Tools"/>
    </section>
  )
}

export default Tools