/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {
  faCircleExclamation,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MainTitle from "../components/MainTitle";

const Profile = () => {
  const { auth, setAuth, setShowLoader } = useAuth();
  const axios = useAxiosPrivate();
  const [userData, setUserData] = useState({});
  const profileRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");

  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  useEffect(() => {
    setUserData(auth.user);
  }, [auth.user]);

  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
  }, [pwd, matchPwd, PWD_REGEX]);

  const uploadFile = async (e) => {
    e.preventDefault();
    setShowLoader(true);

    const selectedFile = e.target.files[0];
    const fd = new FormData();
    fd.append("image", selectedFile, selectedFile.name);
    try {
      const response = await axios.post(
        "/users/update/" + auth.user.id,
        fd
      );
      setAuth({
        email: auth.email,
        pwd: auth.pwd,
        user: response.data.result,
        token: auth.token,
      });
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response");
      } else if (error.response?.status === 400) {
        setErrMsg("Email or Password Missing.");
      } else if (error.response?.status === 401) {
        setErrMsg("Incorrect Email or Password entered.");
      } else {
        setErrMsg("Error Occured. Could not log you in.");
      }
      errRef.current.focus();
    }
    setShowLoader(false);
  };

  const update = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const data = {
      name: userData.name,
      email: userData.email,
      phone_number: userData.phone_number,
    };
    try {
      const response = await axios.post(
        "/users/update/" + auth.user.id,
        data
      );
      setAuth({
        email: auth.email,
        pwd: auth.pwd,
        user: response.data.result,
        token: auth.token,
      });
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response");
      } else if (error.response?.status === 400) {
        setErrMsg("Email or Password Missing.");
      } else if (error.response?.status === 401) {
        setErrMsg("Incorrect Email or Password entered.");
      } else {
        setErrMsg("Error Occured. Could not log you in.");
      }
      errRef.current.focus();
    }
    setShowLoader(false);
  };

  const updatePassword = async (e) => {
    setShowLoader(true);
    const data = {
      password: pwd
    };
    const response = await axios.post(
      "/users/update/"+
      auth.user.id,
      data
    );
    setAuth({
      email: auth.email,
      pwd: auth.pwd,
      user: response.data.result,
      token: auth.token,
    });
    setShowLoader(false);
    window.location.reload();
  };

  return (
    <div>
    <MainTitle title="Profile"/>
      <section className="w-full bg-white rounded-xl shadow-lg py-4 px-4 mb-8">
        <h2 className="font-bold text-lg mb-4">Account Details</h2>
        <div className="flex">
          <div className="w-1/3 mr-4">
            <div className="flex flex-col items-center">
              <label
                htmlFor="photo"
                className="block font-medium text-gray-700 mb-2"
              >
                Profile Picture
              </label>
              <div className="rounded-full overflow-hidden relative w-40 h-40">
                <span
                  className="profile-pic"
                  style={{ backgroundImage: `url(${auth.user.profile_pic})` }}
                ></span>
                <span className="w-full h-full absolute top-0 left-0 flex items-center justify-center bg-gray-200 font-bold opacity-20"></span>
              </div>
              <div className="mt-2 relative rounded-md max-w-xs text-sm">
                <input
                  type="file"
                  hidden
                  ref={profileRef}
                  name="photo"
                  id="photo"
                  onChange={uploadFile}
                  className="shadown-md"
                />
                <p
                  ref={errRef}
                  className={errMsg ? "errmsg" : "offscreen"}
                  aria-live="assertive"
                >
                  {errMsg}
                </p>
                <label
                  htmlFor="photo"
                  className="btn primary mt-2"
                  onClick={(e) => profileRef.current.focus()}
                >
                  Change Photo
                </label>
              </div>
            </div>
          </div>
          <form className="w-2/3 ml-4 relative" onSubmit={update}>
          <div>
            <div className="max-w-sm mb-3">
              <label htmlFor="name" className="block font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1 relative rounded-md">
                <input
                  defaultValue={userData.name}
                  type="text"
                  name="name"
                  required
                  id="name"
                  onChange={(e) => (userData.name = e.target.value)}
                  className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                />
              </div>
            </div>
            <div className="max-w-sm mb-3">
              <label htmlFor="name" className="block font-medium text-gray-700">
                Email Address
              </label>
              <div className="mt-1 relative rounded-md">
                <input
                  defaultValue={userData.email}
                  type="email"
                  required
                  name="email"
                  id="email"
                  onChange={(e) => (userData.email = e.target.value)}
                  className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                />
              </div>
            </div>
            <div className="max-w-sm mb-3">
              <label htmlFor="name" className="block font-medium text-gray-700">
                Phone Number
              </label>
              <div className="mt-1 relative rounded-md">
                <input
                  defaultValue={userData.phone_number}
                  type="number"
                  required
                  name="number"
                  id="number"
                  onChange={(e) => (userData.phone_number = e.target.value)}
                  className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                />
              </div>
            </div>
            <button className="btn primary right-0 bottom-0">
              Update Profile
            </button>
          </div>
          </form>
          
        </div>
      </section>
      <section className="w-full bg-white rounded-xl shadow-lg py-4 px-4 mb-8 mt-8">
        <h2 className="font-bold text-lg mb-4">Account Settings</h2>
        {/* <div className="mb-6">
          <h3 className="font-bold">Two Factor Authentication</h3>
          <p>
            Click the button below to
            {auth.user.two_factor_secret ? (
              <span className="font-bold text-red-700"> disable </span>
            ) : (
              <span className="font-bold text-blue-700"> enable </span>
            )}
            two factor authentication on your account.
          </p>
          <p className="mb-2 font-bold text-xs text-gray-600">
            Two Factor Authentication will send you a one-time code by email
            next time you login.
          </p>
          {auth.user.two_factor_secret ? (
            <button className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-1 px-4 border border-red-500 hover:border-transparent rounded">
              Disable
            </button>
          ) : (
            <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-4 border border-blue-500 hover:border-transparent rounded">
              Enable
            </button>
          )}
        </div> */}
        <div>
          <h3 className="font-bold">Change Password</h3>
          <p className="mb-2">Update your password below.</p>
          <div className="max-w-sm relative mb-3">
            <label htmlFor="pwd">Password</label>
            <input
              type="password"
              id="pwd"
              onChange={(e) => setPwd(e.target.value)}
              required
              aria-invalid={validPwd ? "false" : "true"}
              aria-describedby="pwdnote"
              placeholder="Password"
              onFocus={() => setPwdFocus(true)}
              onBlur={() => setPwdFocus(false)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
            <span
              className={validPwd ? "valid input-check mt-5" : "hide input-check mt-5"}
            >
              <FontAwesomeIcon icon={faCheck} />
            </span>
            <span
              className={
                validPwd || !pwd ? "hide input-check mt-5" : "invalid input-check mt-5"
              }
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
            <p
              id="pwdnote"
              className={pwdFocus && !validPwd ? "instructions" : "offscreen"}
            >
              <FontAwesomeIcon icon={faCircleExclamation} />
              8 to 24 characters. <br />
              Must include uppercase and lowercase letters, a number and a
              special character. <br />
              Allowed special characters:
              <span aria-label="exclamation mark"> !</span>
              <span aria-label="at symbol"> @</span>
              <span aria-label="hashtag"> #</span>
              <span aria-label="dollar sign"> $</span>
              <span aria-label="percent"> %</span>
            </p>
          </div>

          <div className="max-w-sm relative">
            <label htmlFor="confirm_pwd">Confirm Password</label>
            <input
              type="password"
              id="confirm_pwd"
              onChange={(e) => setMatchPwd(e.target.value)}
              required
              aria-invalid={validMatch ? "false" : "true"}
              aria-describedby="confirmnote"
              placeholder="Confirm Password"
              onFocus={() => setMatchFocus(true)}
              onBlur={() => setMatchFocus(false)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
            <span
              className={
                validMatch && matchPwd
                  ? "valid input-check mt-5"
                  : "hide input-check mt-5"
              }
            >
              <FontAwesomeIcon icon={faCheck} />
            </span>
            <span
              className={
                validMatch || !matchPwd
                  ? "hide input-check mt-5"
                  : "invalid input-check mt-5"
              }
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
            <p
              id="confirmnote"
              className={
                matchFocus && matchPwd && !validMatch
                  ? "instructions"
                  : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faCircleExclamation} />
              Must match the first password input.
            </p>
          </div>
          <button
            onClick={updatePassword}
            className={`btn primary mt-2 ${
              !validPwd || !validMatch ? "disabled" : "enabled"
            }`}
          >
            Update Password
          </button>
        </div>
      </section>
    </div>
  );
};

export default Profile;
