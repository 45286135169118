import MainTitle from "../../components/MainTitle"

function Reports() {
  return (
    <section>
      <MainTitle title="Reports"/>
    </section>
  )
}

export default Reports