/* eslint-disable */
import { faBell, faCog, faUser, faRightFromBracket, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAuth from '../hooks/useAuth'

import { Fragment, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { Menu, Transition } from '@headlessui/react'
import useLogout from '../hooks/useLogout'

function Header() {

  const {auth} = useAuth()
  const logout = useLogout()

  const [searchQuery, setSearchQuery] = useState('')

  const onSearchClicked = () => {
    //do something here
  }

  return (
    <header className='bg-white rounded-xl w-full p-4 shadow-lg flex justify-between items-center fixed' style={{width: 'calc(100% - 21rem)', zIndex: 99}}>
        <div className='flex items-center'>
          {/* <input type="text" id="main_search" value={searchQuery} onChange={setSearchQuery} className='border border-gray-200 rounded-md h-9 w-60 px-2 text-sm text-slate-800' placeholder='Search'/>
          <span className='flex justify-center items-center bg-slate-100 rounded-lg text-slate-500 h-9 w-9 mx-2  hover:bg-slate-200 cursor-pointer'>
            <FontAwesomeIcon icon={faArrowRight} onClick={onSearchClicked}/>
          </span> */}
        </div>
        <div className='flex items-center'>
          <div className='ml-4 flex items-center'>
            {/* Settings */}
            {/* <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="flex items-center rounded-md cursor-pointer ml-2">
                  <FontAwesomeIcon icon={faCog} className='flex justify-center items-center text-slate-500 p-1.5 mx-2 text-lg hover:text-slate-700 cursor-pointer' />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute -right-12 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="p-2">
                      <Menu.Item>
                        {({ active }) => (
                          <span>I am a setting</span>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu> */}
          </div>
          <span className='w-0.5 h-full bg-slate-200 relative flex mx-2'>&nbsp;</span>

          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="flex items-center rounded-md cursor-pointer ml-2">
                <span className='font-semibold text-sm mr-2'>Welcome Back, {auth?.user.name}</span>
                <img src={auth.user.profile_pic} alt="User Profile" className='w-7 h-7 rounded-full border border-gray-100'/>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="p-2">
                  <Menu.Item>
                    {({ active }) => (
                      <NavLink to="/profile"
                        className={`${
                          active ? 'bg-blue-500 text-white' : 'text-slate-800'
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                      <FontAwesomeIcon icon={faUser} className="mr-2" />
                        My Profile
                      </NavLink>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                      onClick={logout}
                      className={`${
                          active ? 'bg-blue-500 text-white' : 'text-slate-800'
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                      <FontAwesomeIcon icon={faRightFromBracket} className="mr-2" />
                        Logout
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
    </header>
  )
}

export default Header