import axios from '../api/axios'
import useAuth from './useAuth'

function useRefreshData() {
  const { setAuth } = useAuth()

  const refresh = async () => {
    const token = localStorage.getItem('token')
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    
    if(token) {
      const response = await axios.get('/user', config)
      setAuth((prev) => {
        return { ...prev, token, user: response.data }
      })
      return token
    }
    
  }
  return refresh
}

export default useRefreshData
