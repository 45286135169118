/* eslint-disable */
import MainTitle from "../../../components/MainTitle"

import { useState, useEffect, useRef } from "react"

import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil, faSave, faTimes, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import Modal from "../../../components/Modal"

function Permissions() {
  
  const axios = useAxiosPrivate()

  const {setShowLoader} = useAuth()

  const [roles, setRoles] = useState([])
  const [permissions, setPermissions] = useState([])
  const [newRole, setNewRole] = useState('');
  const [newPermission, setNewPermission] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [editRole, setEditRole] = useState('');
  const [editRoleName, setEditRoleName] = useState('');

  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('')

  const errRoleRef = useRef();
  const [errRoleMsg, setErrRoleMsg] = useState('')

  const [deleteRole, setDeleteRole] = useState({})

  const [deletePermission, setDeletePermission] = useState({})

  const [showModal, setShowModal] = useState(false)

  const [modalTitle, setModalTitle] = useState('')

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setShowLoader(true)
    await Promise.all([getRoles(), getPermissions()])
    setShowLoader(false)
  }

  const getRoles = async () => {
    try {
      const response = await axios.get('/roles/all')
      refreshRoles(response);

    } catch (error) {
      
    }
  }

  const getPermissions = async () => {
    try {
      const response = await axios.get('/permissions/all')
      sortPermissions(response.data.result)

    } catch (error) {
      
    }
  }

  const sortPermissions = (permissions) => {
    const categories = []
    const data = []
    permissions.forEach(permission => {
      if(!categories.includes(permission.category)) {
        categories.push(permission.category)
        data.push({category:permission.category, permissions: []})
      }
      data.forEach(group => {
        if(group.category === permission.category) {
          group.permissions.push({id: permission.id, name: permission.name})
        }
      })        
    })

    setPermissions(data)
  }

  const ShowRoles = ({permission}) => {
    return roles.map(role => {
      return (<td key={role.id} className='border-r border-r-slate-300'>
        <input type="checkbox" defaultChecked={role.permissions.includes(permission.name)} onChange={() => updateRolePermission(role, permission)}/>
      </td>)
    })
    
  }

  const updateRolePermission = async (role, permission) => {
    if(role.permissions.indexOf(permission.name) >= 0) {
      role.permissions.splice(role.permissions.indexOf(permission.name), 1)
    } else {
      role.permissions.push(permission.name)
    }
  }

  const refreshRoles = (response) => 
  {
    response.data.result.map(role => {
      return role.permissions = role.permissions.map(permission => {
        return(permission.name)
      })
    })
    setRoles(response.data.result)
  }

  const updatePermissions = async () => {
    setShowLoader(true)
    try {
      const response = await axios.post('/roles/update', roles)
      refreshRoles(response);

    } catch (error) {
      
    }
    setShowLoader(false)
  }

  const resetModal = () => 
  {
    setDeleteRole({});
    setDeletePermission({});
    setShowModal(false)
    setModalTitle('');
  }

  const addRole = async (e) => 
  {
    e.preventDefault();
    setErrRoleMsg('');
    setShowLoader(true);
    try {
      const response = await axios.post("/roles/create", {
        name: newRole,
        guard_name: "web",
      });
      if (response.data.error === false) {
        refreshRoles(response);
        setNewRole('');
      } else {
        setErrRoleMsg(response.data.message);
      }
    } catch (error) {
      if (!error?.response) {
        setErrRoleMsg("No Server Response");
      } else if (error.response?.status === 422) {
        setErrRoleMsg("The email provided already exists.");
      } else {
        setErrRoleMsg("Error Occured. Could not Reset Password.");
      }
      errRef.current.focus();
    }
    setShowLoader(false);
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


  const addPermission = async (e) => 
  {
    e.preventDefault();
    setErrMsg('');
    setShowLoader(true)
    try{
      const response = await axios.post('/permissions/create', {name: newPermission.toLowerCase(), category: capitalizeFirstLetter(newCategory), guard_name: 'web'})
     if(response.data.error === false)
     {
      sortPermissions(response.data.result);
      setNewPermission('');
      setNewCategory('');
     } else 
     {
      setErrMsg(response.data.message)
     }
    }catch(error)
    {
      if (!error?.response) {
        setErrMsg('No Server Response')
      } else if (error.response?.status === 422) {
        setErrMsg('The email provided already exists.')
      } else {
        setErrMsg('Error Occured. Could not Reset Password.')
      }
      errRef.current.focus()
    }
    setShowLoader(false)
  }

  const updateRole = async (role) => 
  {
    setShowLoader(true)
    try{
      const response = await axios.post('/roles/update/' + role.id, {name: editRoleName})
      setEditRole('');
      setEditRoleName('');
      refreshRoles(response);
    }catch(error)
    {
      console.log(error);
    }
    setShowLoader(false)
  }

  const deleteRoleClicked = role => {
    setModalTitle('Delete Role: ' + role.name)
    setDeleteRole({...role});
    setShowModal(true)
  }

  const deletePermissionClicked = permission => {
    setModalTitle('Delete Permission: ' + permission.name)
    setDeletePermission({...permission});
    setShowModal(true)
  }

  const ModalBody = () => {
    if(deleteRole.id) {
      return <DeleteRoleElement />
    }

    if(deletePermission.id) {
      return <DeletePermissionElement />
    }
    
  }

  const DeleteRoleElement = () => {
    const removeRole = async () => {
      setShowModal(false)
      setShowLoader(true)
      const response = await axios.get('/roles/delete/'+ deleteRole.id)
      refreshRoles(response);
      setDeleteRole({})
      setShowLoader(false);
    }
    return (
      <form onSubmit={removeRole} className="justify-center flex">
        <div className="mb-3">
          <p className="text-xl font-bold my-8 text-center">Are you sure you want to delete this role?</p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">
                Delete Role
              </button>

              <button type="button" className="btn mt-4 w-1/2" onClick={resetModal}>
                Cancel
              </button>
          </div>
          
        </div>
    </form>
    )
  }

  const DeletePermissionElement = () => {
    const removePermission = async () => {
      setShowModal(false)
      setShowLoader(true)
      const response = await axios.get('/permissions/delete/'+ deletePermission.id)
      sortPermissions(response.data.result);
      setDeletePermission({})
      setShowLoader(false);
    }
    return (
      <form onSubmit={removePermission} className="justify-center flex">
      <div className="mb-3">
        <p className="text-xl font-bold my-8 text-center">Are you sure you want to delete this permission?</p>
        <div className="flex mt-2 w-full">
          <button className="btn red mt-4 mr-4 w-1/2">
              Delete Permission
            </button>

            <button type="button" className="btn mt-4 w-1/2" onClick={resetModal}>
              Cancel
            </button>
        </div>
        
      </div>
    </form>
    )
  }
  
  return (
    <section className="relative">
      {showModal && <Modal title={modalTitle} body={<ModalBody />} show={resetModal}/> }
      <MainTitle title="Permissions" backButton />

      {/* CRUD for roles */}

      <div className="flex">
        <div className="info-box">
          <div>
            <table className="min-w-full border text-center rounded-xl overflow-hidden bg-slate-100">
              <tbody>
                <tr className="border-b border-slate-300">
                  <td className="border-r-slate-300 capitalize p-2 font-bold px-4 py-2 border-r bg-slate-800 text-white">
                    Role
                  </td>
                  <td className="border-r-slate-300 capitalize p-2 font-bold px-4 py-2 border-r bg-slate-800 text-white">
                    Actions
                  </td>
                </tr>
                {roles.map((role) => {
                  return (
                    <tr className="border-b border-slate-300">
                      <td className="border-r border-r-slate-300 capitalize p-2">
                        {editRole === role.name ? (<input value={editRoleName} className="w-full flex" onChange={(e) => setEditRoleName(e.target.value)}/>) : (
                        <span>{role.name}</span>
                        )}
                      </td>
                      <td className="border-r border-r-slate-300 capitalize p-2">
                        <div className="flex justify-center">
                          { editRole === role.name ? (
                            <>
                          <span onClick={() => updateRole(role)} className="flex justify-center items-center bg-blue-400 rounded-lg text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer">
                            <FontAwesomeIcon icon={faSave} />
                          </span>
                          <span onClick={() => {setEditRole(''); setEditRoleName('')}} className="flex justify-center items-center bg-yellow-400 rounded-lg text-yellow-800 h-9 w-12 mx-2  hover:bg-yellow-500 cursor-pointer">
                          <FontAwesomeIcon icon={faTimes} />
                          </span>
                          </>
                          ) : (<span onClick={() => {setEditRole(role.name); setEditRoleName(role.name)}} className="flex justify-center items-center bg-blue-400 rounded-lg text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer">
                            <FontAwesomeIcon icon={faPencil} />
                          </span>)}
                          <span className="flex justify-center items-center bg-red-400 rounded-lg text-red-800 h-9 w-12 mx-2  hover:bg-red-500 cursor-pointer" onClick={() => deleteRoleClicked(role)}>
                            <FontAwesomeIcon icon={faTrashCan}/>
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="info-box">
            <form onSubmit={addRole}>
              <h2>Create New Role</h2>
              <div ref={errRoleRef} className={errRoleMsg ? 'errmsg' : 'offscreen'} aria-live='assertive'>
                  {errRoleMsg}
              </div>
              <input type="text" placeholder="Role Name" minLength={2} maxLength={32} required onChange={(e) => setNewRole(e.target.value)} id="newRole" value={newRole}/>
              <button type="submit" className="btn primary mt-4 w-3/4">Add Role</button>
            </form>
        </div>
        <div className="info-box">
            <form onSubmit={addPermission}>
              <h2>Create New Permission</h2>
              <div ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live='assertive'>
                  {errMsg}
              </div>
              <input type="text" placeholder="Permission Name" minLength={2} maxLength={32} required onChange={(e) => setNewPermission(e.target.value)} id="newPermission" value={newPermission} />
              <input type="text" placeholder="Category Name" className="mt-2" minLength={2} maxLength={32} required onChange={(e) => setNewCategory(e.target.value)} id="newCategory" value={newCategory} />
              <button type="submit" className="btn primary mt-4 w-3/4">Add Permission</button>
            </form>
        </div>
      </div>

      <table className="min-w-full border text-center bg-white table-auto rounded-xl overflow-hidden">
        <thead className="border-b">
          <tr>
            <th
              scope="col"
              className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
            >
              Permission
            </th>
            {roles.map((role) => {
              return (
                <th
                  scope="col"
                  className="font-bold px-4 py-2 border-r bg-slate-800 text-white"
                  key={role.id}
                >
                  {role.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {permissions.map((permission) => {
            return (
              <>
                <tr key={permission.id} className="bg-slate-200 w-full">
                  <td
                    colSpan={roles.length + 1}
                    className="font-semibold text-sm py-2"
                  >
                    {permission.category}
                  </td>
                </tr>
                {permission.permissions.map((data) => {
                  return (
                    <tr key={data.id} className="border-b border-slate-300">
                      <td
                        className="border-r border-r-slate-300 capitalize p-2 text-left flex justify-between items-center group"
                        key={data.id}
                      >
                        {data.name}
                        <span className="justify-center items-center text-red-800 cursor-pointer hidden group-hover:flex" onClick={() => deletePermissionClicked(data)}><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></span>
                      </td>
                      <ShowRoles permission={data} />
                    </tr>
                  );
                })}
              </>
            );
          })}
        </tbody>
      </table>

      <div className="flex justify-end mt-10 w-full">
        <button onClick={updatePermissions} className="btn primary">
          Update Permissions
        </button>
      </div>
    </section>
  );
}

export default Permissions