import axios from "axios"
const BASE_URL = 'https://app-api.schoolwellbeing.com/api'
// const BASE_URL = "http://localhost:8000/api"

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
})

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
})
