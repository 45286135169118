import { Outlet } from "react-router-dom";
import logo from "../img/swb-logo.png";
// import Header from '../components/Header'
// import LeftBar from '../components/LeftBar'

function AuthLayout() {
  return (
    <div className="flex">
      {/* <Header /> */}

      <div
        className="relative flex w-full h-screen justify-center items-center"
      >
        {/* Left */}
          <div className="flex items-center flex-col z-10 relative w-full">
            <div className="flex">
              <img src={logo} alt="School Wellbeing Logo" className="w-72" />
            </div>
            <div
              className="py-8 mt-4 px-8 w-full"
              style={{ maxWidth: 500, minHeight: 200 }}
            >
             
              <Outlet />
            </div>
          </div>
        {/* </main> */}
        {/* Right */}
      </div>
    </div>
  );
}

export default AuthLayout;
