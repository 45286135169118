/* eslint-disable */
import { Routes, Route } from "react-router-dom"

import Login from "./pages/Login"
import TwoFa from './pages/TwoFactor'
// import Register from './pages/Register'
import ForgotPassword from "./pages/ForgotPassword"
import ResetPassword from "./pages/ResetPassword"

import Missing from "./pages/Missing"

// Admin pages
import AdminDashboard from "./pages/Admin/Dashboard"
import AdminReports from "./pages/Admin/Reports"
import AdminCalendar from "./pages/Admin/Calendar"
import AdminAccounts from "./pages/Admin/Accounts"
import AdminSettings from "./pages/Admin/Settings"
import AdminPermissions from "./pages/Admin/Settings/Permissions"
import Services from "./pages/Admin/Services"
import QuickLinks from "./pages/Admin/QuickLinks"
// import Podcasts from "./pages/Admin/Podcasts"
import PushNotifications from "./pages/Admin/PushNotifications"
import Enquiries from "./pages/Admin/Enquiries"
import AdminUsers from "./pages/Admin/Settings/Users"
import AdminTools from "./pages/Admin/Tools"

import Layout from "./components/Layout"

import MainLayout from "./layouts/MainLayout"
import AuthLayout from "./layouts/AuthLayout"

import RequireAuth from "./components/RequireAuth"
import PersistLogin from "./components/PersistLogin"
import CheckLogin from "./components/CheckLogin"

// import useAuth from "./hooks/useAuth"
import Profile from "./pages/Profile"
import ViewEnquiry from "./pages/Admin/ViewEnquiry"

function App() {
  // const { admin } = useAuth()

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public Routes */}

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route element={<MainLayout />}>
              <Route>
                <Route path="/" element={<AdminDashboard />} />
                <Route path="/calendar" element={<AdminCalendar />} />
                <Route path="/accounts" element={<AdminAccounts />} />
                <Route path="/reports" element={<AdminReports />} />
                <Route path="/settings" element={<AdminSettings />} />
                <Route path="/services" element={<Services />} />
                <Route path="/enquiries" element={<Enquiries />} />
                <Route path="/quick-links" element={<QuickLinks />} />
                {/* <Route path="/podcasts" element={<Podcasts />} /> */}
                <Route
                  path="/push-notifications"
                  element={<PushNotifications />}
                />
                <Route path="/enquiry/:id" element={<ViewEnquiry />} />
                <Route
                  path="/settings/permissions"
                  element={<AdminPermissions />}
                />
                <Route path="/settings/users" element={<AdminUsers />} />
                <Route path="/tools" element={<AdminTools />} />
              </Route>

              <Route path="/profile" element={<Profile />} />
            </Route>

            {/* Page without using main layout */}
            {/* <Route path='/appointments/new' element={<NewAppointment />} /> */}
            <Route path="*" element={<Missing />} />
          </Route>

          <Route element={<CheckLogin />}>
            <Route element={<AuthLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="2fa" element={<TwoFa />} />
              {/* <Route path='register' element={<Register />} /> */}
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
          </Route>

          {/* Catch All */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App
