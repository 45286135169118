/* eslint-disable */
import { NavLink } from "react-router-dom"

import {
  faBell,
  faBriefcaseMedical,
  faCogs,
  faHouse,
  faLink,
  faMailBulk,
  faPalette,
  faPodcast,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import useAuth from "../../hooks/useAuth"

const navClasses =
  "w-full text-white py-3 px-3 flex relative rounded-lg items-center"

function Nav() {
  const { auth } = useAuth()
  return (
    <ul>
      {/* {can('access dashboard') ? ( */}
      <li className="mb-2">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive
              ? "bg-blue-500 " + navClasses + " pointer-events-none"
              : navClasses + " hover:bg-slate-700"
          }
        >
          <FontAwesomeIcon icon={faHouse} className="mr-4 text-xl" />
          Home
        </NavLink>
      </li>
      {/* ) : null} */}

      {/* {auth.user.role === 1 ? ( */}
        <li className="mb-2">
          <NavLink
            to="/services"
            className={({ isActive }) =>
              isActive
                ? "bg-blue-500 " + navClasses
                : navClasses + " hover:bg-slate-700"
            }
          >
            <FontAwesomeIcon
              icon={faBriefcaseMedical}
              className="mr-4 text-xl"
            />
            Services
          </NavLink>
        </li>
      {/* ) : null} */}

      {/* {auth.user.role === 1 ? (
        <li className="mb-2">
          <NavLink
            to="/podcasts"
            className={({ isActive }) =>
              isActive
                ? "bg-blue-500 " + navClasses
                : navClasses + " hover:bg-slate-700"
            }
          >
            <FontAwesomeIcon icon={faPodcast} className="mr-4 text-xl" />
            Podcasts
          </NavLink>
        </li>
      ) : null} */}

      <li className="mb-2">
        <NavLink
          to="/enquiries"
          className={({ isActive }) =>
            isActive
              ? "bg-blue-500 " + navClasses
              : navClasses + " hover:bg-slate-700"
          }
        >
          <FontAwesomeIcon icon={faMailBulk} className="mr-4 text-xl" />
          Enquiries
        </NavLink>
      </li>

      <li className="mb-2">
        <NavLink
          to="/quick-links"
          className={({ isActive }) =>
            isActive
              ? "bg-blue-500 " + navClasses
              : navClasses + " hover:bg-slate-700"
          }
        >
          <FontAwesomeIcon icon={faLink} className="mr-4 text-xl" />
          Quick Links
        </NavLink>
      </li>

      <li className="mb-2">
        <NavLink
          to="/push-notifications"
          className={({ isActive }) =>
            isActive
              ? "bg-blue-500 " + navClasses
              : navClasses + " hover:bg-slate-700"
          }
        >
          <FontAwesomeIcon icon={faBell} className="mr-4 text-xl" />
          Push Notifications
        </NavLink>
      </li>
      <li className="mb-2">
        <NavLink
          to="/settings"
          className={({ isActive }) =>
            isActive
              ? "bg-blue-500 " + navClasses
              : navClasses + " hover:bg-slate-700"
          }
        >
          <FontAwesomeIcon icon={faCogs} className="mr-4 text-xl" />
          Settings
        </NavLink>
      </li>
    </ul>
  )
}

export default Nav
