import { Outlet, Navigate, useLocation } from "react-router-dom"
import { useState, useEffect } from "react"
// import useRefreshData from '../hooks/useRefreshData'
import useAuth from "../hooks/useAuth"
import MainLoader from "./MainLoader"

const CheckLogin = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [redirect, setRedirect] = useState(false)
  const { auth } = useAuth()
  const location = useLocation()

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  useEffect(() => {
    let isMounted = true
    const redirectUser = async () => {
      try {
        setRedirect(true)
      } catch (error) {
        console.log(error)
      } finally {
        await delay(250)
        isMounted && setIsLoading(false)
      }
    }
    auth.token ? redirectUser() : setIsLoading(false)

    return () => (isMounted = false)
  }, [auth])

  useEffect(() => {}, [isLoading])

  if (!redirect) {
    return <>{isLoading ? <MainLoader /> : <Outlet />}</>
  }
  return (
    <>
      {isLoading ? (
        <MainLoader />
      ) : (
        <Navigate to="/" state={{ from: location }} replace />
      )}
    </>
  )
}

export default CheckLogin
