import { NavLink, Link } from 'react-router-dom';
import logo from '../img/swb-logo.png';

import { faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useLogout from "../hooks/useLogout";
// import useAuth from '../hooks/useAuth';

import AdminNav from '../components/Admin/Nav'

const navClasses = "w-full text-white py-3 px-3 flex relative rounded-lg items-center"

function LeftBar() {
  // const {admin} = useAuth()
  const logout = useLogout();

  return (
    <aside className="bg-slate-800 fixed inset-0 z-50 my-4 ml-4 h-[calc(100vh-32px)] w-72 rounded-xl shadow-lg">
      <div className="relative border-b border-b-slate-600 mx-4">
        <Link to="/" className="flex items-center py-4 px-8">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="m-4 flex flex-col justify-between relative h-[calc(100%-120px)]">
        <ul className="mb-4 flex flex-col gap-1 mt-4">
          <AdminNav />

        </ul>

        <ul className='border-t border-t-slate-600 pt-4'>
        <li className="mb-2">
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                isActive ? 'bg-blue-500 ' + navClasses + ' pointer-events-none' : navClasses + ' hover:bg-slate-700'
              }            >
              <FontAwesomeIcon icon={faUser} className="mr-4 text-xl" />
              Profile
            </NavLink>
          </li>
          <li className="mb-2">
            <button
              onClick={logout}
              className="w-full text-white py-3 px-3 flex relative rounded-lg items-center hover:bg-slate-700"
            >
              <FontAwesomeIcon icon={faRightFromBracket} className="mr-4 text-xl" />
              Logout
            </button>
          </li>
        </ul>
      </div>
    </aside>
  );
}

export default LeftBar;
