// import { faTimes } from "@fortawesome/free-solid-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function Modal({title, body, show}) {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center" style={{zIndex: 999}}>
       <div className="bg-slate-700/50 w-full h-full">&nbsp;</div>
        <div className='flex flex-col absolute p-4 bg-white w-[500px] rounded-xl shadow-lg'>
          {/* <span className="absolute right-1 top-1 flex justify-center items-center bg-red-300 rounded-full text-red-800 h-5 w-5 text-sm hover:bg-red-400 cursor-pointer" onClick={() => show(false)}>
            <FontAwesomeIcon icon={faTimes} className=''/>
          </span> */}
          <h2 className='text-left text-wba-primary text-2xl font-semibold border-b border-b-slate-300 pb-2'>{title}</h2>
          <div className="mt-4">
            {body}
          </div>
        </div>
    </div>
  )
}

export default Modal