import MainTitle from "../../components/MainTitle"

function Calendar() {
  return (
    <section>
      <MainTitle title="Calendar"/>
    </section>
  )
}

export default Calendar